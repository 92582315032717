/*!

=========================================================
* Argon Dashboard PRO Angular - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap functions
@import 'bootstrap/functions';
@import 'bootstrap/mixins';

// Argon functions
@import 'custom/functions';
@import 'custom/variables';
@import 'bootstrap/variables';

// Argon mixins
@import 'custom/mixins';

// Bootstrap components
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

// Argon components
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

// Angular changes
@import 'angular/dropdowns';
@import 'angular/navbar-vertical';
@import 'angular/progressbar';
@import 'angular/plugin-ngx-chips';
@import 'angular/ngx-toastr';
@import 'angular/mobius1-selectr';
@import 'angular/tooltips';
@import 'angular/plugin-ngx-datatable';
@import 'angular/navbar-vertical';
@import 'angular/vector-map';

//@import "~ngx-toastr/toastr-bs4-alert";
