//
// select2.scss
// Select2 plugin overrides
//

.select2-selection__arrow {
    display: none;
}

.select2.select2-container {
    width: 100% !important;
}

.select2-container .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-size: $font-size-base;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @if $enable-rounded {
        // Manually use the if/else instead of the mixin to account for iOS override
        border-radius: $input-border-radius;
    } @else {
        // Otherwise undo the iOS default
        border-radius: 0;
    }

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus();

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: inherit;
    line-height: inherit;
}

.select2-dropdown {
    padding: $dropdown-padding-y 0;
    background-color: $dropdown-bg;
    border: 1px solid $input-border-color;
    border-radius: $dropdown-border-radius;
}

.select2-results__option {
    padding: $dropdown-item-padding-y $input-padding-x;
    background-color: $dropdown-link-bg;
    color: $dropdown-link-color;
    font-size: $font-size-sm;

    @include hover-focus {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-hover-bg);
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected='true'] {
    background-color: $dropdown-link-active-bg;
    color: $dropdown-link-active-color;
}

.select2-container--default .select2-results__option[aria-disabled='true'] {
    color: $dropdown-link-disabled-color;
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--multiple {
    height: auto;
    min-height: $input-height;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    display: block;
    margin: 0 0 -0.25rem -0.25rem;
    padding: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    display: inline-flex;
    padding: 0 0.5rem;
    margin: 0 0 0.25rem 0.25rem;
    background-color: $light;
    border: none;
    border-radius: $border-radius-sm;
    line-height: ($input-line-height * $font-size-base);
    font-size: $font-size-sm;
    color: $input-color;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    order: 2;
    margin-left: 0.5rem;
    color: $text-muted;

    &:hover {
        color: $body-color;
    }
}

.select2-container .select2-search--inline {
    display: none;
}

.select2-selection[aria-expanded='true'] {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

// Search bar

.select2-search--dropdown {
    padding: $dropdown-item-padding-y $input-padding-x;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    // height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-line-height-sm;
    @include border-radius($input-border-radius-sm);
}

// Sizing
//
// Makes the select2 containers respect the .form-control-sm and .form-control-lg classes

.form-control-sm + .select2-container .select2-selection--single,
.form-control-sm + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-sm + .select2-container--default .select2-selection--multiple {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-line-height-sm;
    @include border-radius($input-border-radius-sm);
}

.form-control-sm + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-sm + .select2-container--default .select2-selection--multiple {
    min-height: $input-height-sm;
}

.form-control-sm + .select2-container--default .select2-selection--multiple .select2-selection__choice {
    line-height: ($input-line-height-sm * $font-size-sm);
}

.form-control-lg + .select2-container .select2-selection--single,
.form-control-lg + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-lg + .select2-container--default .select2-selection--multiple {
    height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-line-height-lg;
    @include border-radius($input-border-radius-lg);
}

.form-control-lg + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-lg + .select2-container--default .select2-selection--multiple {
    min-height: $input-height-lg;
}

.form-control-lg + .select2-container--default .select2-selection--multiple .select2-selection__choice {
    line-height: ($input-line-height-lg * $font-size-lg);
}
