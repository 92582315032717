//
// Heading
//

// General styles

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
    color: inherit;
}

.heading {
    letter-spacing: $heading-letter-spacing;
    font-size: $heading-font-size;
    text-transform: $heading-text-transform;
    font-weight: $heading-font-weight;
}

// Heading variations

.heading-small {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}

.heading-title {
    letter-spacing: $heading-title-letter-spacing;
    font-size: $heading-title-font-size;
    font-weight: $heading-title-font-weight;
    text-transform: $heading-title-text-transform;
}

.heading-section {
    letter-spacing: $heading-section-letter-spacing;
    font-size: $heading-section-font-size;
    font-weight: $heading-section-font-weight;
    text-transform: $heading-section-text-transform;

    img {
        display: block;
        width: 72px;
        height: 72px;
        margin-bottom: 1.5rem;
    }

    &.text-center {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Surtitles

.surtitle {
    text-transform: uppercase;
    color: $gray-600;
    letter-spacing: 2px;
    margin-bottom: 0;
}
